import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import ContactIcon from '@/presentation/assets/icons/contact-icon.svg'
import FinancialIcon from '@/presentation/assets/icons/financial-icon.svg'
import GasIcon from '@/presentation/assets/icons/gas-icon.svg'
import OrderIcon from '@/presentation/assets/icons/order-icon.svg'
import { DueInvoicesComponent } from '@/presentation/components/Feedback/DueInvoices/DueInvoicesComponent'
import { IMainMenuItem } from '@/presentation/components/Navigation/MainMenu/MainMenuComponent.types'
import { usePagePermission } from '@/presentation/hooks/UsePagePermission/UsePagePermission'
import { useToast } from '@/presentation/hooks/UseToast/UseToast'
import { useSelector } from '@codebox-team/redux'
import { IconComponent } from '@codebox-team/web/components'
import {
  COLORS,
  EUserRoles,
  selectUserDetails,
  useDueInvoicesFetch,
} from '@customerportal/core'

const useDueInvoicesToast = () => {
  const [isToastDismissed, setIsToastDismissed] = useState<boolean>(false)

  const history = useHistory()
  const toast = useToast()

  const { data: financialDueInvoices, handleFetch: fetchFinancialDueInvoices } =
    useDueInvoicesFetch()

  const handleToast = useCallback(() => {
    toast({
      content: (
        <DueInvoicesComponent
          action={() => history.push('/financial')}
          title={financialDueInvoices?.title}
          subTitle={financialDueInvoices?.subTitle}
        />
      ),
      message: '',
      options: {
        autoClose: false,
        closeButton: () => <IconComponent icon="close" />,
        onClose: () => setIsToastDismissed(true),
        position: 'bottom-center',
        style: {
          backgroundColor: COLORS.white,
          border: `2px solid ${COLORS.red}`,
          borderRadius: '4px',
          padding: '10px 10px 20px 20px',
          width: '380px',
        },
      },
      title: '',
    })
  }, [
    financialDueInvoices?.subTitle,
    financialDueInvoices?.title,
    history,
    toast,
  ])

  return {
    fetchFinancialDueInvoices,
    financialDueInvoices,
    handleToast,
    isToastDismissed,
  }
}

export const useAppLayoutPrivateProviderRules = () => {
  const history = useHistory()
  const userDetails = useSelector(selectUserDetails)

  const {
    fetchFinancialDueInvoices,
    financialDueInvoices,
    handleToast,
    isToastDismissed,
  } = useDueInvoicesToast()

  const userHasManagerRegistrationAccess = usePagePermission(
    EUserRoles.PortalManagerRegistration,
  )
  const userHasBusinessGroupsAccess = usePagePermission(
    EUserRoles.PortalBusinessGroups,
  )
  const userHasUserAccessAccess = usePagePermission(EUserRoles.PortalUserAccess)
  const userHasFinancialAccess = usePagePermission(EUserRoles.PortalFinancial)
  const userHasOrdersCreationAccess = usePagePermission(
    EUserRoles.PortalOrdersCreation,
  )
  const userHasOrdersTrackingAccess = usePagePermission(
    EUserRoles.PortalOrdersTracking,
  )
  const userHasParametersAccess = usePagePermission(EUserRoles.PortalParameters)
  const userHasMarketingAccess = usePagePermission(EUserRoles.PortalMarketing)

  const userIsCustomer =
    !userHasManagerRegistrationAccess && !userHasBusinessGroupsAccess

  const currentPathName = history.location.pathname.split('/')[1] ?? ''

  const customerMenu: IMainMenuItem[] = [
    ...(userHasOrdersCreationAccess
      ? [
          {
            path: '/',
            icon: GasIcon,
            label: translate.t('screens.home.order_gas.title'),
          },
        ]
      : []),
    ...(userHasOrdersTrackingAccess
      ? [
          {
            path: '/orders',
            icon: OrderIcon,
            label: translate.t('screens.home.orders.title'),
          },
        ]
      : []),
    ...(userHasFinancialAccess
      ? [
          {
            path: '/financial',
            icon: FinancialIcon,
            label: translate.t('screens.home.financial.title'),
          },
        ]
      : []),
    {
      path: '/contact',
      icon: ContactIcon,
      label: translate.t('screens.home.contact.title'),
    },
  ]

  const customerManagerMenu: IMainMenuItem[] = [
    ...(userHasUserAccessAccess
      ? [
          {
            path: '/user-access',
            label: translate.t('screens.profile_customer.manage_access.title'),
          },
          {
            path: '/register-user',
            label: translate.t('screens.profile_customer.register_user.title'),
          },
        ]
      : []),
    {
      path: '/my-profile',
      label: translate.t('screens.profile_customer.my_profile.title'),
    },
  ]

  const managerMenu: IMainMenuItem[] = [
    ...(userHasManagerRegistrationAccess
      ? [
          {
            path: '/manager-registration',
            label: translate.t(
              'screens.profile_manager.manager_registration.title',
            ),
          },
        ]
      : []),
    ...(userHasBusinessGroupsAccess
      ? [
          {
            path: '/business-groups',
            label: translate.t('screens.profile_manager.business_groups.title'),
          },
        ]
      : []),
    ...(userHasParametersAccess
      ? [
          {
            path: '/params',
            label: translate.t('screens.params.title'),
          },
        ]
      : []),
    ...(userHasMarketingAccess
      ? [
          {
            path: '/marketing',
            label: translate.t('screens.marketing.title'),
          },
        ]
      : []),
  ]

  const menuItems: IMainMenuItem[] = {
    '': customerMenu,
    orders: customerMenu,
    financial: customerMenu,
    contact: customerMenu,
    cart: customerMenu,
    'order-review': customerMenu,
    feedback: customerMenu,
    stock: customerMenu,
    'user-access': customerManagerMenu,
    'register-user': customerManagerMenu,
    'my-profile': customerManagerMenu,
    params: managerMenu,
    marketing: managerMenu,
    'manager-registration': managerMenu,
    'contact-manager': managerMenu,
    'business-groups': managerMenu,
    'business-groups-registration': managerMenu,
    'business-group-detail': managerMenu,
    'terms-and-policies-manager': managerMenu,
  }[currentPathName]

  useEffect(() => {
    if (userDetails?.customerId) {
      fetchFinancialDueInvoices({ customerId: userDetails?.customerId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.customerId])

  useEffect(() => {
    if (financialDueInvoices?.quantity > 0 && !isToastDismissed) {
      handleToast()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialDueInvoices?.quantity, isToastDismissed])

  return { menuItems, userIsCustomer }
}
