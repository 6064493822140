import React from 'react'

import { SelectCustomerComponent } from '@/presentation/components/Inputs/SelectCustomer/SelectCustomerComponent'
import { CartSummaryComponent } from '@/presentation/components/Navigation/CartSummary/CartSummaryComponent'
import { HeaderMenuComponent } from '@/presentation/components/Navigation/HeaderMenu/HeaderMenuComponent'
import { HeaderComponent } from '@/presentation/components/Surfaces/Header/HeaderComponent'
import {
  useHeaderContainerRules,
  useUserCustomers,
} from '@/presentation/containers/Header/HeaderContainer.rules'
import { GridComponent, ToolbarComponent } from '@codebox-team/web/components'

export const HeaderContainer = () => {
  const { cartProducts, handleLogout, isLoading, userDetails } =
    useHeaderContainerRules()

  const userCustomers = useUserCustomers()

  return (
    <HeaderComponent>
      <ToolbarComponent>
        <GridComponent container>
          <GridComponent item xs={3}>
            <HeaderMenuComponent
              handleLogout={handleLogout}
              userDetails={userDetails}
              isLoading={isLoading}
            />
          </GridComponent>

          {!userDetails?.isAdmin && (
            <>
              <GridComponent item xs={6}>
                <SelectCustomerComponent {...userCustomers} />
              </GridComponent>

              <GridComponent
                item
                display="flex"
                gap="60px"
                justifyContent="flex-end"
                xs={3}>
                <CartSummaryComponent cartProducts={cartProducts} />
              </GridComponent>
            </>
          )}
        </GridComponent>
      </ToolbarComponent>
    </HeaderComponent>
  )
}
